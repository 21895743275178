import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    checkotp(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .get('otpverify', { params: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      });
    },
    otpresend(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .get('otpresend', { params: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      });
    },
    plans(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/pricing', { params: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      });
    },
    paymentverify(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/pricingverify', { params: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      });
    },
    paymentdone(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('paymentdone', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      });
    },
  }
  }