<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">
            <!-- Brand logo-->
            <b-link class="brand-logo">
                <frp-logo />

                <h2 class="brand-text text-primary ml-1">FA</h2>
            </b-link>
            <b-col lg="6" class="d-none d-lg-flex align-items-center p-5">
                <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                    <b-img :src="imgUrl" fluid alt="Register V2" />
                </div>
            </b-col>
            <b-col lg="6" class="d-flex align-items-center auth-bg p-lg-3">
                <b-col sm="8" md="10" lg="12" class="px-xl-2 mx-auto">
                    <b-card-title title-tag="h2" class="font-weight-bold mb-1">
                        <span class="text-primary">&#8377</span> Simple Pricing
                    </b-card-title>
                    <b-card-text class="mb-2">
                        No hidden fees. Only pay what you see below...
                    </b-card-text>
                    <div>
                        <b-row>
                            <b-col sm="12" md="6" class="mt-1" v-for="item, index in reldata" :key="'pricing' + index">
                                <b-card class="text-center">
                                    <b-card-title class="text-primary">
                                        {{ item.name }}
                                    </b-card-title>
                                    <h1 class=" mt-2text-primary">
                                        &#8377 {{ item.total }}
                                    </h1>
                                    <h3 class="text-center">
                                        <span v-if="item.days > 0">{{ item.days }} Days /</span>
                                        <span v-if="item.months > 0">{{ item.months }} Months /</span>
                                        <span v-if="item.year > 0">{{ item.year }} Year /</span>
                                    </h3>
                                    <hr>
                                    {{ item.details }}
                                    <b-button block type="button" class="mt-2" @click="checkpricing(item)"
                                        variant="outline-primary">
                                        {{ item.total > 0 ? 'Buy Now' : 'Start Free Trial' }}
                                    </b-button>
                                </b-card>
                            </b-col></b-row>
                    </div>
                </b-col>
            </b-col>
        </b-row>
    </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import inputtext from "@/views/Component/Input/inputtext.vue";
import axios from "@axios";
import FrpLogo from "@core/layouts/components/Logo.vue";
import {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BLink,
    BFormInput,
    BButton,
    BImg,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import { required } from "@validations";
import router from "@/router";

import { initialAbility } from "@/libs/acl/config";
import { useToast } from "vue-toastification/composition";
import store from "@/store/index";
import StoreModule from "./StoreModule";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
    isUserLoggedIn,
    getUserData,
    getHomeRouteForLoggedInUser,
} from "@/auth/utils";
import formValidation from "@core/comp-functions/forms/form-validation";

import useJwt from "@/auth/jwt/useJwt";
import Ripple from "vue-ripple-directive";

export default {
    components: {
        FrpLogo,
        BRow,
        BCol,
        BButton,
        BCard,
        BCardTitle,
        BCardText,
        BForm,
        inputtext,
        BFormGroup,
        BImg,
        BInputGroup,
        BLink,
        BFormInput,
        BInputGroupAppend,
        ValidationProvider,
        ValidationObserver,
        BCard,
        BFormInput,
        BButton,
        ToastificationContent,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            userEmail: "",
            cPassword: "",
            password: "",
            sideImg: require("@/assets/images/pages/reset-password-v2.svg"),
            required,
            password1FieldType: "password",
            password2FieldType: "password",
        };
    },
    setup(props, { emit }) {
        const GENAPP_APP_STORE_MODULE_NAME = "auth-payment";
        const modulename = "Plans";
        const modulenamesub = "Plans";
        const reldata = ref([]);
        let optdata = router.currentRoute.params.data;
        if (!store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
            store.registerModule(GENAPP_APP_STORE_MODULE_NAME, StoreModule);
        const toast = useToast();
        onUnmounted(() => {
            if (store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
                store.unregisterModule(GENAPP_APP_STORE_MODULE_NAME);
        });

        const onSubmit = (data) => {
            var jx = {
                id: optdata.id || null,
                razorpay_payment_id: data.razorpay_payment_id || null,
                razorpay_order_id: data.razorpay_order_id || null,
            };
            store
                .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/paymentdone", jx)
                .then((response) => {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: "Success",
                            text:
                                modulename +
                                (edittype == 1 ? " editted" : " added") +
                                " successfully",
                            icon: "CheckIcon",
                            variant: "success",
                        },
                    });
                    // router
                    //   .push({
                    //     name: GENAPP_APP_STORE_MODULE_NAME + "-view",
                    //     params: {
                    //       id: response.data.id,
                    //     },
                    //   })
                    //   .catch(() => {});
                })
                .catch((error) => {
                    if (error.response.status === 404) {
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: "Error",
                                text: "Couldnt find " + modulenamesub + " details",
                                icon: "AlertTriangleIcon",
                                variant: "danger",
                            },
                        });
                    }
                });
        };

        let key = 0;
        let edittype = 0;
        let storetype = "addData";

        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

        if (!optdata) {
            router
                .push({
                    name: "auth-plans",
                    params: {},
                })
                .catch(() => { });
            return;
        }
        return {
            GENAPP_APP_STORE_MODULE_NAME,
            edittype,
            modulename,
            modulenamesub,
            key,
            today,
            optdata,
            reldata,
            onSubmit,
        };
    },
    computed: {
        imgUrl() {
            if (store.state.appConfig.layout.skin === "dark") {
                this.sideImg = require("@/assets/images/pages/reset-password-v2-dark.svg");
                return this.sideImg;
            }
            return this.sideImg;
        },
    },
    mounted() {
        var self = this;
        const userData = getUserData()
        if (!this.optdata) {
            router
                .push({
                    name: "auth-payment",
                    params: {},
                })
                .catch(() => { });
            return;
        }
        var options = {
            key: "rzp_test_wqvf4DGv5auT9C",
            amount: this.optdata.data.total * 100,
            currency: "INR",
            name: "TechVoyager",
            description: "YOUR COMPANY DESCRIPTION",
            image: "YOUR COMPANY IMAGE",
            prefill: {
                name: userData.name,
                email: userData.email,
                contact: userData.gendata.phone || ''
            },
            order_id: this.optdata.data.paydetails.orderID,
            handler: function (response) {
                alert(response.razorpay_payment_id);
                alert(response.razorpay_order_id);
                alert(response.razorpay_signature);
            },
            theme: {
                color: "#F37254",
            },
        };
        options.handler = function (response) {
            // document.getElementById("razorpay_payment_id").value =
            //   response.razorpay_payment_id;
            // document.getElementById("razorpay_signature").value =
            //   response.razorpay_signature;
            // document.razorpayform.submit();
            self.onSubmit(response);
        };
        options.theme.image_padding = false;
        options.modal = {
            ondismiss: function () {
                router.push("auth-otp ");
                window.location.href = "/plans";
            },
            escape: true,
            backdropclose: false,
        };
        var rzp1 = new Razorpay(options);
        rzp1.on("payment.failed", function (response) {
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.order_id);
            alert(response.error.metadata.payment_id);
        });
        rzp1.open();
    },
    methods: {
        logout() {
            localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
            localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
            localStorage.removeItem("userData");
            this.$ability.update(initialAbility);
            this.$router.push({ name: "auth-login" });
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
